@font-face {
  font-family: gerova;
  src: url(../fonts/Gerova.otf);
}


/* ////FOR SORTING BUTTONS  */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");




/*/* ///BUTTON CSS */
.buttonDivMain{
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.373);
  height: 30%;
  width: 100%;
  padding: 10px 10px ;
  border-radius: 20px;
}



/* /////BUTTONS  */
.buttonNew {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 10px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  padding: 4px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  will-change: transform;
}

.buttonNew:disabled {
  pointer-events: none;
}

.buttonNew:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.buttonNew:active {
  box-shadow: none;
  transform: translateY(0);
}

/* //////// */



/* ////FOR SORTING BUTTONS  */


.deptHeadDiv{
  background-color: red;
  text-align: center;
  color: white;
  font: 60px;
}
.deptHeading{
  
}


.events_section {
  background: url(./backGround.svg);
  padding: 150px 50px;
  width: 100vw;
}
.heading {
  font-family: gerova;
  text-align: center;
  font-size: 6vw;
  color: #fff;
  /* background-clip: text; */
  /* background-image: linear-gradient(to bottom right, #ff00ee, #ff0932); */
}

.events_container {
  margin-top: 50px;
  background: url(./backGround.svg);
  width: auto;
  /* background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("./Hero.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  flex-wrap: wrap;
  padding: 0 50px;
}



/* ///BUTTON CSS */
.buttonDivMain{
margin-top: 20px;
   margin-bottom: 20px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   background-color: rgb(221, 255, 3);
   height: 30%;
   width: 100%;
   overflow: hidden;
   padding: 10px 10px ;
   border-radius: 20px;
 
 }
 
 
 
 /* /////BUTTONS  */
 .buttonNew {
   appearance: none;
   background-color: transparent;
   border: 2px solid #1A1A1A;
   border-radius: 10px;
   box-sizing: border-box;
   color: #3B3B3B;
   cursor: pointer;
   display: inline-block;
   font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
   font-size: 20px;
   font-weight: 600;
   line-height: normal;
   outline: none;
   padding: 4px 30px;
   text-align: center;
   text-decoration: none;
   transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   width: fit-content;
   will-change: transform;
 }
 
 .buttonNew:disabled {
   pointer-events: none;
 }
 
 .buttonNew:hover {
   color: #fff;
   background-color: #1A1A1A;
   box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
   transform: translateY(-2px);
 }
 
 .buttonNew:active {
   box-shadow: none;
   transform: translateY(0);
 }

 /* //////// */
@media screen and (max-width: 768px) {
  .heading{
    margin-top: -15%;
    font-size: 15vw;
  }
  .events_container {
    padding: 0;
  }
  .Dept{
    font-size: 15px;
    width: 40vw;
  }
  .CardDiv{
    /* background-color: red; */
    padding: 60px 10px 5px 10px;
    height: 60vh;
  }
  .leaderboard_section {
    padding: 100px 20px 10px 20px;
  }

  .buttonDivMain{
    /* background-color: red; */
    border-radius: 10px;
    padding: 4px 10px;
  }
  .buttonNew {
    border-radius: 5px;
    font-size: 10px;
    font-weight: bolder;
    padding: 1px 5px;
  }

}


