.buttonHolder {
  position: relative;
}

.svg {
  position: absolute;
}

.electric {
  padding: 20px 50px;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(106, 123, 252, 0.13);
  border: 2px solid rgb(125, 140, 255);
  color: white;
  position: relative;
  filter: blur(0px);
}

#electric2 {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(1px);
  background-color: rgba(106, 123, 252, 0.414);
  border: 2px solid rgb(125, 140, 255);
  color: rgb(182, 191, 255);
  /* display: none; */
  z-index: -1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.yellow {
  width: 110%;
  stroke-dasharray: 300;
  stroke-dashoffset: 1000;
  animation: dash 1s linear;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.blue {
  width: 110%;
  stroke-dasharray: 400;
  stroke-dashoffset: 1000;
  animation: dash 1.2s linear;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

#y1 {
  top: -115%;
  left: -5%;
  filter: blur(0.5px);
}

#y2 {
  width: 120%;
  left: -10%;
  top: -155%;
  filter: brightness(1);
}

#b1 {
  left: -5%;
  top: -125%;
  /* filter: blur(.5px) */
}

#b2 {
  left: -10%;
  top: -165%;
  width: 120%;
  /* filter: blur(.5px) */
}

@keyframes dot1 {
  from {
    bottom: 0%;
    left: 10%;
    opacity: 1;
  }
  to {
    bottom: -30%;
    left: 0%;
    opacity: 0;
  }
}
@keyframes dot2 {
  from {
    top: 0%;
    left: 30%;
    opacity: 1;
  }
  to {
    top: -30%;
    left: 40%;
    opacity: 0;
  }
}

@keyframes dot3 {
  from {
    top: 0%;
    left: 80%;
    opacity: 1;
  }
  to {
    top: -30%;
    left: 80%;
    opacity: 0;
  }
}
@keyframes dot4 {
  from {
    bottom: 20%;
    left: 100%;
    opacity: 1;
  }
  to {
    bottom: 30%;
    left: 120%;
    opacity: 0;
  }
}

.part {
  content: " ";
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: rgb(183, 226, 255);
  z-index: 2;
  position: absolute;
  filter: blur(1px);
  display: none;
}

#part1 {
  animation: dot1;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}
#part2 {
  animation: dot2;
  transform-origin: center;
  animation-duration: 1.6s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}
#part3 {
  animation: dot3;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

#part4 {
  animation: dot4;
  transform-origin: center;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
}

.buttonHolder {
  width: max-content;
  max-height: 20px;
}

.hide {
  opacity: 0;
}

#electric:hover + .hide {
  opacity: 1;
}
#electric:hover .hide {
  opacity: 1;
}

.electric:hover .part {
  display: flex;
}
