@font-face {
  font-family: gerova;
  src: url(../fonts/Gerova.otf);
}
@font-face {
  font-family: korgen;
  src: url(../fonts/Korgen.otf);
}
@font-face {
  font-family: orangeq;
  src: url(../fonts/Orange-Squash-Demo-BF6483cfe8b2bfc.otf);
}
@font-face {
  font-family: perita;
  src: url(../fonts/Pecita.otf);
}
@font-face {
  font-family: clashPlay;
  src: url(../fonts/ClashDisplay-Bold.otf);
}



.hero {
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("./Hero.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 290vh;
}
.rightImg{
  position: relative;
  bottom: -10%;
  width: 40%;
}

.herobox {
  /* background-color: rgb(29, 29, 29); */
  /* background: rgba(255,255,255,0.2); */
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255,255,255,0.1);

  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  padding: 4rem;
}

.headerbox {
  background: rgba(255,255,255,0.2);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255,255,255,0.1);
/* background-color: red; */
  border-radius: 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-family: clashPlay;
  margin-bottom: 20px;
   font-size: 5rem;
  font-weight: bolder;
  display: inline-block;
  letter-spacing: 1px;
  color: #FEC800;
}
.logo {
  margin-bottom: 3.5rem;
}
.text_img {
  margin-top: 30px;
  width: 300px;
}

.caption {
  font-style: italic;
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
}

.btn {
  display: block;
  background-image: linear-gradient(to top right, #09b326, #32b4ed);
  padding: 1.1rem 0.5rem;
  border: none;
  border-radius: 10px;
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
  width: 30rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  transform: translateY(-0.1rem);
  background-image: linear-gradient(to top left, #09b326, #32b4ed);
}

.btn:active {
  transform: translateY(0);
}

.anchorBtn {
  text-decoration: none;
}

.date {
  border-bottom: 2px solid black;
  color: black;
  background-color: rgb(242, 255, 0);
  padding: 2px 20px;
  border-radius: 10px;
  font-family: gerova;
  font-size: 3rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.countdownbox {
  color: #fff;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 3rem 5rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.countdown {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.column {
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

/* ////////// 2nd Page Kavi Sammelan  */
.Promopage2{
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url("./Hero.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
height: 90vh;
width: 100vw;
/* -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255,255,255,0.1); */
/* background-color: red; */
}
.main2Div{
  height: 100%;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255,255,255,0.1);
}
.topDiv2{
  height: 25%;
  /* background-color: red; */
}
.topTag{
  margin-top: 50px;
  margin-left: 7%;
}
.botDiv2{
  height: 75%;
  /* background-color: #0263ad; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kavis{
  margin-top: -10%;
  width: 80%;
  filter: drop-shadow(2px -3px 3px yellow);
}
.botTag{
  padding: 25px 20px;
  text-align: center;
  font-size: 30px;
  width: 100%;
  background-color: #FEC800;
  font-family: gerova;
  color: black;
}



/* ///////// 3rd Page ANKIT PROMO */

.Promopage3{
  height: 100vh;
  width: 100vw;
  margin-bottom: 20%;
  }
  .main3Div{
    height: 100%;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255,255,255,0.1);
  }
  .topDiv{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: red; */
  }
  .imgTop{
  width: 50%;
  margin-left: -21%;
  }
  .imgText{
    width: 30%;
  }
  .botDiv{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
  }
  .midDiv{
    /* background-color: red; */
    height: 100%;
  }
  .midText{
    font-size: 35px;
    margin-left: -10%;

    font-family: perita;
    background-color: #09b326;
    border-radius: 20px;
    padding: 8px 30px;
  }
  .imgSunny{
    width: 30%;
    margin-left: 14%;
  }
  .imgBot{
    margin-top: 5%;
    margin-left: 30%;
    width: 80%;
  }







@media screen and (max-width: 768px) {


  
/* ////////// 2nd Page Kavi Sammelan  */
.Promopage2{
  margin-top: 30px;
height: 60vh;
width: 100vw;
/* background-color: red; */
}
.topDiv2{
  height: 25%;
  /* background-color: red; */
}
.topTag{
  width: 90%;
  margin-top: 50px;
}
.botDiv2{
  margin-top: 10%;
  height: 50%;
  /* background-color: #0263ad; */
}
.kavis{
  margin-top: -10%;
  width: 100%;
}
.botTag{
  padding: 15px 20px;
  font-size: 15px;
  font-family: orangeq;

}
/* //////////////3rd Page */


.Promopage3{
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
  url("./Hero.svg");
  margin-right: 20%;
background-repeat: no-repeat;
background-size: cover;
height: 80vh;
}



  .herobox {
    justify-content: center;
    flex-direction: column;
    height: 110vh;
  }
  .rightImg{
    margin-top: -10%;
    width: 90%;
    margin-bottom: 30%;
  }

  .hero {
    background-position: center center;
    height: 120vh;
    /* margin-top: 650px; */
  }

  .text_img {
    margin-top: -5px;
    width: 170px;
  }
  .headerbox {
    padding: 2rem 2rem;
    
  }

  .heading {
    font-size: 6rem;
  }

  .btn {
    display: block;
    font-size: 1.5rem;
    width: 10rem;
  }

  .countdownbox {
    column-gap: 1rem;
  }


  /* //////3rd PROMO PAGE */

  
  .topDiv{
    margin-top: 10px;
    height: 30vh;
    flex-direction: column;
    /* background-color: rgb(255, 110, 110); */
  }
  .imgTop{
  width: 80%;
  margin-left: -50%;
  }
  .imgText{
    width: 50%;
    margin-right: -50%;
  }


  .botDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(93, 93, 248); */
  }
  .midDiv{
    /* background-color: violet; */
    height: 100%;
    overflow: hidden;
  }
  .midText{
    font-size: 15px;
    margin-left: 15%;
    font-family: perita;
    background-color: #09b326;
    border-radius: 15px;
    padding: 4px 15px;
  }
  .imgSunny{
    width: 70%;
    margin-left: -12%;
  }
  .imgBot{
    overflow: hidden;
    margin-top: 5%;
    margin-left: 30%;
    width: 90%;
  }
}
