.background{
    width: 100vw;
    height: auto;
    background: url(./backGround.svg);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
  
  .registerForm{
    width: 45vw;
    height: auto;
    margin-top: 15vh;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:36px 16px 16px 16px;
    color: white;
    overflow: hidden;
    margin-bottom: 36px;
  }
  
  .heading{
    font-size:3vw;
    font-weight: 600;
  }
  
  .userData{
    width: 100%;
    padding: 8px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .mandatory {
    color: #ff00e1;
  }
  
  .input_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 85%;
    margin: 8px;
  }
  
  .input_label {
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    color: #ffffff;
    margin-bottom: 6px;
    padding-left: 12px;
  }
  
  .input{
    /* all: unset; */
    background-color: #00000000;
    border: 1.5px solid #d9d9d9;
    padding: 8px 16px;
    width: 100%;
    height: 7vh;
    color: white;
    font-size: 16px;
    border-radius: 15px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  
  .button{
    margin-top: 10px;
      margin-bottom: 70px;
  }

  @media screen and (max-width:768px){
    .registerForm{
      width: 90vw;
      align-self: center;
      padding:36px 8px 8px 8px;
      margin-right:16px;
    }
    .heading{
      font-size:4vh;
      font-weight: 600;
    }
  
    .userData{
      padding: 4px;
      margin: 4px;
    }
  
    .input_container{
      width:100%
    }
  
    .input_label{ 
       font-size: 2.5vh;
    }
  
    .input{
      font-size: 2vh;
    }
  
    .background{
      padding: 0px;
    }
    
  }
  