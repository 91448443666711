@font-face {
  font-family: gerova;
  src: url(../fonts/Gerova.otf);
}
@font-face {
  font-family: kaftan;
  src: url(../fonts/kaftan-trial.otf);
}

.background {
  width: 100vw;
  height: auto;
  background: url(./backGround.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.432);
}

.mainDiv{
  user-select: none;
margin-top: 30px;
border-radius: 10px;
height: 60vh;
width: 97vw;
background-color: #c6ecff1f;
text-align: center;
padding: 30px 10px;
color : white;
}

.mainDiv > h3{
  padding: 5px;
  border-bottom: 2px solid black;
  font-family: kaftan;
  font-size: 40px;
  margin-bottom: 10px;
  
}
.mainDiv >h4{
  font-family: gerova;
  font-size: 25px;
  margin-bottom: 30px;
}











/* /////BUTTONS  */
.buttonNew {
  appearance: none;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  padding: 4px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  will-change: transform;
}

.buttonNew:disabled {
  pointer-events: none;
}

.buttonNew:hover {
  color: #fff700;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.buttonNew:active {
  box-shadow: none;
  transform: translateY(0);
}
/* 
.registerForm {
  width: 45vw;
  height: auto;
  margin-top: 15vh;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 36px 16px 16px 16px;
  color: white;
  overflow: hidden;
  margin-bottom: 36px;
} */

.card {
  margin-top: 150px;
  margin-bottom: 50px;
  width: 45vw;
  height: auto;
  /* height: 450px; */
  /* background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%); */
  border-radius: 20px;
  transition: all 0.3s;
  /* margin: 20px; */
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: white;
}
.card:hover {
  transform: translateY(-20px);
}

.card2 {
  /* width: 300px;
  height: 450px; */
  padding: 36px 16px 16px 16px;
  width: 45vw;
  height: auto;
  background-color: #1a1a1a60;
  border-radius: 20px;
  border: 0.1px solid #ffffff41;
  transition: all 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}

.card2:hover , .card2:active{
  transform: scale(0.98);
  border-radius: 20px;
  background-color: #1a1a1a;
}

.card:hover, .card:active {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
}

.heading {
  margin-top:24px ;
  font-size: 3vw;
  font-weight: 600;
}

.userData {
  width: 100%;
  padding: 8px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mandatory {
  color: #ff00e1;
}

.input_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 85%;
  margin: 8px;
}

.input_label {
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 6px;
  padding-left: 12px;
}

.input,
.message {
  /* all: unset; */
  background-color: #00000000;
  border: 1.5px solid #d9d9d9;
  padding: 8px 16px;
  width: 100%;
  height: 7vh;
  color: white;
  font-size: 16px;
  border-radius: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.message {
  height: 15vh;
}

.button {
  margin-top: 10px;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  /* .registerForm {
    width: 90vw;
    align-self: center;
    padding: 36px 8px 8px 8px;
    margin-right: 16px;
  } */
  .card,.card2{
    width: 90vw;
    margin-right: 16px;
  }
  .card2{
    padding: 36px 8px 8px 8px;
  }

  .heading {
    font-size: 4vh;
    font-weight: 600;
  }

  .userData {
    padding: 4px;
    margin: 4px;
  }

  .input_container {
    width: 100%;
  }

  .input_label {
    font-size: 2.5vh;
  }

  .input,
  .message {
    font-size: 2vh;
  }

  .background {
    padding: 0px;
  }
}
