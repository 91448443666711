
@font-face {
  font-family: kaftan;
  src: url(../fonts/kaftan-trial.otf);
}

.header {
  font-family: kaftan;
  position: fixed;
  width: 95%;
  left: 50%;
  transform: translate(-50%);
   /* padding: -3.3rem -2.5rem;  */
  color: #fff;
  z-index: 100;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
}

.scrolledHeader {
  font-family: kaftan;
  width: 95%;
  position: fixed;
  /* top: 3%; */
  padding: 7px 7px;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(48, 48, 48, 0.932);
  color: #fff;
  z-index: 100;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
}

.elements {
  display: flex;
  align-items: center;
}

.logobox {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-right: auto;
}

.logo {
  width: 7%;
}

.hamburger {
  display: none;
}

.heading {
  font-size: 2.1rem;
}

.navbox {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
}

.navLink {
  align-items: center;
  text-decoration: none;
  list-style: none;
  margin-right: 3rem;
  font-weight: 500;
  cursor: pointer;
}

.navLink:after {
  content: "";
  display: block;
  width: 100%;
  transform: scaleX(0);
  height: 3.5px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #ff00ee, #ff0932);

  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

.homeLink {
  color: inherit;
}

.navLink:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropLink {
  width: 8.5rem;
  display: inline-block;
  text-align: center;
  align-items: center;
  text-decoration: none;
  margin-right: 3rem;
  cursor: pointer;
  background-image: linear-gradient(to bottom right, #ff00ee, #ff0932);

  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  color: transparent;
  font-weight: 700;
}



.btn:active {
  transform: translateY(0);
}

.mobileBox {
  display: none;
}

.none {
  display: none;
}

.mobileNav li {
  list-style: none;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.mobileNav li:nth-child(4) {
  margin-top: 2rem;
}

.mobLink {
  display: inline;
  padding: 2rem;
  font-size: 2.7rem;
  background-image: linear-gradient(to bottom right, #ff00ee, #ff0932);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  color: transparent;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 15%;
  }

  .nav {
    display: none;
  }

  .btn {
    display: none;
  }


  .hamburger {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .none {
    width: 95%;
    position: absolute;
    top: -50rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.527);
    border-radius: 10px;
    padding: 1.3rem 2rem;
    text-align: center;
    transition: all 0.4s;
  }

  .mobileBox {
    width: 95%;
    position: absolute;
    top: 12rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.89);
    border-radius: 10px;
    padding: 1.3rem 2rem;
    text-align: center;
    transition: all 0.8s;
  }
}
