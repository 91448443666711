.contact {
  height: 100%;
  padding: 3rem 1.5rem;
}

.contactBox {
  width: 100%;
  display: flex;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.432);
  padding: 5rem 5rem;
  border-radius: 15px;
  display: block;
}

.headingBox {
  width: 100%;
  text-align: center;
  position: relative;
  padding-bottom: 4rem;
}

.heading {
  color: #fff;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 600;
}

.heading::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 4px;
  text-align: center;
  background-image: linear-gradient(90deg, #09b35c 2.27%, #32b4ed 97.67%);
}

.contentBox {
  display: flex;
  gap: 4rem;
}

.persons {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.personBox {
  background: linear-gradient(
    180deg,
    #09b35c 0%,
    rgba(50, 180, 237, 0.63) 100%
  );
  color: #fff;
  height: auto;
  padding: 1.2rem 3rem;
  border-radius: 8px;
}

.personBox:not(:last-child) {
  margin-bottom: 5rem;
}

.personHeading {
  font-size: 2.5rem;
}

.details > p {
  display: inline;
  font-size: 1.7rem;
  margin-right: 3rem;
}

.anchor {
  text-decoration: none;
  color: #fff;
  font-size: 2.1rem;
}

.mapBox {
  align-self: center;
  width: 100%;
  height: 40rem;
  background: #000;

  border-radius: 4px;
}

.map {
  width: 100%;
  height: 100%;

  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .contact{
    /* margin-top: -10vh; */
  }
  .heading::after {
    width: 100px;
  }

  .contentBox {
    flex-direction: column;
    gap: 6rem;
    align-items: center;
  }

  .persons {
    align-items: center;
  }
}
