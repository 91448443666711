.svg_frame{
  top: -50%;
  left: -50%;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}
#PreImg{
  background-size: cover;
}
#PreTag{
  font-size: 5rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}