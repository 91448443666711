.about_page {
  padding-top: 100px;
  background: url(../assets/backGround.svg);
  /* padding: 150px 50px; */
  width: 100vw;
}

.schedule_section {
  background: url(../assets/backGround.svg);
  padding: 150px 50px;
  width: 100vw;
}
.headingx {
  text-align: center;
  font-size: 6vw;
  color: #fff;
  /* background-clip: text; */
  /* background-image: linear-gradient(to bottom right, #ff00ee, #ff0932); */
}
