@font-face {
  font-family: gerova;
  src: url(../components/fonts/Gerova.otf);
}
@font-face {
  font-family: kaftan;
  src: url(../components/fonts/kaftan-trial.otf);
}
@font-face {
  font-family: sweetSign;
  src: url(../components/fonts/SweetSensationsPersonalUse-lRgq.ttf);
}
@font-face {
  font-family: recoleta;
  src: url(../components/fonts/Recoleta-RegularDEMO.otf);
}

@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);

.leaderboard_section {
  background: url(../assets/backGround.svg);
  padding: 150px 20px 10px 20px;
  width: 100vw;
  font-family: "Open Sans";
  font-size: 16px;
  background-color: #eee;
}
.heading {
  font-family: gerova;
  text-align: center;
  font-size: 5rem;
  color: #fff;
  margin-bottom: 20px;
}
.para {
  text-align: center;
  color: #fff;
  font-size: 16px;
}






/* ///BUTTON CSS */
.buttonDivMain{
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: rgba(245, 245, 245, 0.373);
  height: 30%;
  width: 100%;
  padding: 10px 10px ;
  border-radius: 20px;

}



/* /////BUTTONS  */
.buttonNew {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 10px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  padding: 4px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  will-change: transform;
}

.buttonNew:disabled {
  pointer-events: none;
}

.buttonNew:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.buttonNew:active {
  box-shadow: none;
  transform: translateY(0);
}

/* //////// */


.Dept{
  padding: 2px 40px;
  /* font-family: kaftan; */
  width: max-content;
  background-color: #000000;
  border-radius: 15px;
  color: rgb(255, 255, 255);
}
.CardDiv2{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 15px;
 
}



.CardDiv{
  text-align: center;
  padding: 80px 0 5px 0;
  width: 100%;
  height: 70vh;
  border-radius: 20px;
  background-color: white;
  background:rgba(35, 20, 20, 0.344);
  margin-bottom: 25px;
}
.divForCards{
width: 100%;
display: flex;
justify-content: space-around;
}
.card {
    /* width: 200px;
    height: 300px; */
    width: 12.5em;
    height:18.75em;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
}

.card a {
    color: inherit;
    text-decoration: none;
}

.card:hover {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}


.card__thumb {
    height: 245px;
    overflow: hidden;
    background-color: #000;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.card__thumb img {
    width: 100%;
    display: block;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.card:hover .card__thumb {
    height: 180px;
}

.card:hover .card__thumb img {
    opacity: 0.6;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.card__body {
    position: relative;
    height: 50px;
    padding: 10px 10px;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

/* .card:hover .card__body {
    height: 50px;
} */

.card__category {
    position: absolute;
    top: -15px;
    border-radius: 10px;
    left: -2px;
    height: 25px;
    padding: 0 15px;
    background-color: coral;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 25px;
}

.card__title {
  font-family: kaftan;
    margin: 0;
    padding: 0 0 10px 0;
    color: #000;
    font-size: 26px;
    font-weight: bold;
    /* text-transform: uppercase; */
}
.card__title2 {
  font-family: kaftan;
    margin-top: -12px;
    padding: 0 0 10px 0;
    color: #000;
    font-size: 11px;
    font-weight: bold;
    /* text-transform: uppercase; */
}

.card__subtitle {
  font-family: orangeq;
    font-size: 15px;
    color: #CB0101;
}

.card__description {
  font-weight: bold;
  padding: 2px 5px 2px 5px;
    font-size: 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card:hover .card__description {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.card__footer {
    position: absolute;
    bottom: 12px;
    left: 20px;
    right: 20px;
    font-size: 11px;
    color: #A3A9A2;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 2px;
    font-size: 18px;
}

.icon+.icon {
    padding-left: 10px;
}



/* /////////////// ALLL COORDINATORS */
.allTeam{
  background-color: rgba(255, 255, 255, 0.511);
  height: max-content;
  width: 97vw;
  padding: 5px 10px;
  border-radius: 12px;

}
.branchTeamDivMain{
  /* background-color: blue; */
  width: 100%;
  height: fit-content;
  padding: 5px 10px;
  margin-bottom: 50px;
}
.branchTeamDivMain>h3{
  /* color: #365E32; */
  font-weight: bolder;
  font-family: recoleta;
  font-size: 20px;
  margin-bottom: 10px;
  
}
.branchTeam{
  background-color: rgba(255, 255, 255, 0.457);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}
.branchTeam>h5{
  color: #365E32;
 font-family: sweetSign;
 font-weight: 100;
  font-size: 30px;
}




@media screen and (max-width: 768px){
  .Dept{
    font-size: 15px;
    width: 40vw;
  }
  .CardDiv{
    /* background-color: red; */
    padding: 60px 10px 5px 10px;
    height: 60vh;
  }
  .leaderboard_section {
    padding: 100px 20px 10px 20px;
  }

  .buttonNew {
    font-size: 10px;
    font-weight: bolder;
    padding: 4px 10px;
  }
  
  /* //for card */
  .card{
    width: 150px;
    height: 250px;
  }
.card__thumb {
  height: 200px;
}
.card:hover .card__thumb {
  height: 150px;
}
  

.card__category {
  font-family: kaftan;
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 1px;

}

.card__title {
  font-size: 15px;
}
.card__title2 {
  margin-top: -10px;
  font-size: 9px;
  margin-bottom: 10px;
}

.card__subtitle {
  font-size: 10px;
}

.card__description {
  font-size: 10px;
}






}