@font-face {
  font-family: gerova;
  src: url(../fonts/Gerova.otf);
}
@font-face {
  font-family: gelica;
  src: url(../fonts/Fontspring-DEMO-gelica-bold.otf);
}
.faqSection {
  flex-basis: 70%;
}

.heading {
  font-family: gerova;
  text-align: center;
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
}

.heading:after {
  content: "";
  display: block;
  width: 15%;
  margin: 3px auto;
  height: 3.5px;
  background-image: linear-gradient(90deg, #09b35c 2.27%, #32b4ed 97.67%);
}

.faq {
  border-radius: 8px;
  padding: 1.2rem;
  color: #fff;
  max-height: 40rem;
  overflow: auto;
}

.faq::-webkit-scrollbar {
  width: 4px;
}

.faq::-webkit-scrollbar-thumb {
  background: rgba(97, 93, 93, 0.507);
}

.single {
  width: 50rem;
  position: relative;
  display: block;
  padding: 1.5rem 0.9rem;
  background: rgba(0, 0, 0, 0.541);
  border-radius: 9px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.single:not(:last-child) {
  margin-bottom: 0.9rem;
}

.question {
  font-size: 1.9rem;
  transition: all 0.2s ease;
}

.answer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.4rem;
  max-height: 100px;
  transition: max-height 0.5s;
  color: #f2f2f2;
  padding: 1.8rem 0 0 0;
  /* letter-spacing: 0.3rem; */
  opacity: 80%;
}

.noAnswer {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  opacity: 80%;
}

.btn {
  position: absolute;
  font-size: 2.8rem;
  top: 6px;
  right: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .single {
    width: 40rem;
  }
  .faqSection{
    /* margin-top: -20vh; */
    /* background-color: red; */
  }
}
