@font-face {
  font-family: kaftan;
  src: url(../fonts/kaftan-trial.otf);
}
@font-face {
  font-family: recoleta;
  src: url(../fonts/Recoleta-RegularDEMO.otf);
}
@font-face {
  font-family: sign;
  src: url(../fonts/Funky\ Signature.ttf);
}
@font-face {
  font-family: gerova;
  src: url(../fonts/Gerova.otf);
}
@font-face {
  font-family: korgen;
  src: url(../fonts/Korgen.otf);
}
@font-face {
  font-family: tessa;
  src: url(../fonts/Allison\ Tessa\ Oblique.ttf);
}


.footer {
  background: linear-gradient(to right, #00093c, #191919);
  background-color: transparent;
  
  color: white;
  margin: 0;
  display: flex;
  flex-direction: row;
  padding: 30px 60px;
  justify-content: space-around;
  align-items: center;
  /* margin: auto; */
  flex-wrap: wrap;
}

.footer_first {
  width: 35%;
  text-align: left;
  padding: 20px 40px;
}
.footer_imran {
  margin: 20px 0;
  font-family: goodtimingbd;
  font-size: 2rem;
  margin-left: 10px;
}
.footer_imran_description {
  margin: 10px 0;
  width: 80%;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 16px;
}
.footer_second {
  width: 50%;
}

.footer_page_link {
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 1rem;
  text-transform: uppercase;
}
.footer_page_link:hover {
  color: grey;
}

.footer_menu_heading {
  margin-bottom: 20px;
  font-weight: bolder;
  font-size: 2.3rem;
  font-family: recoleta;
  color: #ff5bc8;
}

.footer_third {
  width: 29%;
  text-align: left;
}
.footer_creator_main{
  display: flex;
  justify-content: center;
  justify-content: center;
  /* background-color: red; */
}
.footer_creator_div {
border-radius: 20px;
  width: 60%;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  padding: 0px 2px;
  display: flex;
  justify-content: center;
  gap: 30px;
  text-align: center;
  color: rgb(0, 0, 0);
}
.footer_a_link {
  color: #060606;
  text-decoration: none;
  cursor: pointer;
}

.footer_social_item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0; */
}

.footer_social_link {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.footer_creator {
  color: black;
  font-family: gerova;
  font-weight: bolder;
 
 
  padding: 6px 0;
  font-size: 18px;
}
.footer_contact {
  display: flex;
  margin-bottom: 10px;
  margin: 20px 0;
  align-items: center;
  font-size: 16px;
}

.footer_contact_image {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  /* margin:10px; */
}


.footer_imran_image_div {
  display: flex;
  justify-content: left;
  align-items: center;
}
.footer_imran_image {
  height: 40px;
}
.footer_grabbit_image {
  height: 50px;
}

@media (max-width: 500px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
  }
  .footer_first {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin-bottom: 40px;
  }
  .footer_imran_description {
    width: 100%;
    text-align: center;
    font-size: 15px;
  }
  .footer_second {
    width: 80%;
    margin: 10px 0px;
    text-align: center;
  }
  .footer_third {
    width: 100%;
    font-size: 15px;
  }
  .footer_menu_heading {
    text-align: center;
    white-space: nowrap;
  }
  .footer_social_item {
    justify-content: center;
  }
  .footer_creator_main{
    /* background-color: red; */
   padding: 5px 5px;
  }
  .footer_creator_div {
  width: 100%;

  }
  .footer_creator{
    font-size: 13px;
  }
  
  .footer_imran_image_div {
    flex-direction: column;
  }
  
  .mail {
    font-size: 12px;
  }
}
