@font-face {
  font-family: fontNEW;
  src: url(./kaftan-trial.otf);
}
.card {
  width: 300px;
  height: 450px;
  border-radius: 20px;
  transition: all 0.3s;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.card:hover {
  transform: translateY(-15px);
}

.card2 {
  width: 300px;
  height: 450px;
  background-color: #1a1a1a60;
  border-radius: 20px;
  border: 0.1px solid #ffffff41;
  transition: all 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}

.card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
  background-color: #1a1a1a;
}

.card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
 background-color: rgb(252, 219, 35);
}
.img_container {
  width: 250px;
  height: 250px;
  border-radius: 20px;
}
.event_name {
  font-family: fontNEW;
  font-size: 22px;
  color: #fff;
}
.event_image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.view_more_btn {
  margin-top: 20px;
}
